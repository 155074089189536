import * as React from 'react'
import styles from "./Who.module.css"
import classNames from 'classnames'
import { Link } from "react-router-dom"
export default function Who({ containerRef }) {

    return (
        <section ref={containerRef} className={styles.whoSection}>
            <div className={classNames(styles.borderedDiv)}>
                <Link to="/who-we-are/detail">
                    <span className={styles.left}>
                        Mision
                        <hr className={styles.line} />
                    </span>
                </Link>
                <Link to="/who-we-are/detail?section=team" >
                    <span className={classNames(styles.right, {
                        [styles.middleRight]: false
                    })} >
                        Team
                        <hr className={styles.line} />
                    </span>
                </Link>

                <span className={styles.centered}>
                    Who we are
                </span>
                <Link to="/who-we-are/detail?section=services">
                    <span className={classNames(styles.bottom, {
                        [styles.bottomLeft]: false
                    })}>
                        Services
                        <hr className={styles.line} />
                    </span>
                </Link>
            </div>
        </section >

    )
}

