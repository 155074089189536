import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Events.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"
const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/eve-01.png?alt=media&token=6df7eebf-ffa0-4f4e-be31-048f59f75ca0"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/eve-02.png?alt=media&token=2ea6a204-aa57-4c09-85b1-b976fefb0196"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/eve-03.png?alt=media&token=26ff0a04-d75e-4ccc-a9b0-bab0ad0c67e7"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/eve-04.png?alt=media&token=bc6e00f6-7af8-4f25-8aac-04e7ed1e7a5a"

export default function Curation() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>

            <div className={styles.navBar}>
                <title>Events</title>
                <Link to="/home?section=events">
                    <p className={styles.arrow}>Events</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.serviceDetail}>
                <h2 className={styles.title}>Event curation</h2>
                <div className={styles.serviceHeader}>
                    <div>
                        <p>Our team will take on the production and curation of your event. From branding and concept development, to rentals, staff sourcing or music bookings among others.
                        </p>
                    </div>
                    <a href="mailto:info@oddpleasures.com" aria-label="Mail" rel="noopener noreferrer">
                        <button className={styles.btn}>Book now</button>
                    </a>
                </div>
                <div className={styles.serviceContent}>
                    <LazyLoadImage effect="blur" src={img1} alt="events representation" />
                    <LazyLoadImage effect="blur" src={img2} alt="events representation" />
                    <LazyLoadImage effect="blur" src={img3} alt="events representation" />
                    <LazyLoadImage effect="blur" src={img4} alt="events representation" />
                </div>
            </section >
        </section>


    )
}