import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Events.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"
const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/event3.png?alt=media&token=d7334220-a271-44d7-9c26-a3b87a86380a"
const img = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/opfinal2%201.png?alt=media&token=6214ff75-35f4-4733-aaca-c73bdde885ce"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra1.png?alt=media&token=2464b9ec-7026-4753-ae80-b4e785fe52b1"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra2.png?alt=media&token=9b9872b2-38b5-441f-867f-c8b4adb61ffe"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra3.png?alt=media&token=8f93adc8-a266-4a30-a45f-77326d5b7db5"
const img5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra4.png?alt=media&token=7e7eed83-06d1-4f51-946d-3aff06c8f844"
const img6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra5.png?alt=media&token=5c9a35ea-4df8-4abc-a789-6295c94d14ed"

export default function Showcase() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/events/past">
                    <p className={styles.arrow}>Past</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.sectionDetail}>
                <h2 className={styles.title}>The SHOWCASE</h2>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img} alt="showcase event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>THE showcase @ wynwood factory</h3>
                            <p>June 4th, 2021</p>
                            <p>Miami</p>
                            <p>&bull; Adrien Calvet</p>
                            <p>&bull; Avstin Frank</p>
                            <p>&bull; Coupe Melba</p>
                            <p>&bull; E.T.H (Italy)</p>
                            <p>&bull; Miluhska</p>
                            <p>&bull; Odd Ones Out</p>
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}>
                    <LazyLoadImage effect="blur" src={img1} alt="showcase representation" />
                    <LazyLoadImage effect="blur" src={img2} alt="showcase representation" />
                    <LazyLoadImage effect="blur" src={img3} alt="showcase representation" />
                    <LazyLoadImage effect="blur" src={img4} alt="showcase representation" />
                    <LazyLoadImage effect="blur" src={img5} alt="showcase representation" />
                    <LazyLoadImage effect="blur" src={img6} alt="showcase representation" />
                </div>
            </section >
        </section>


    )
}