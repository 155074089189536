import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Events.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"
const img = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/poster-01.png?alt=media&token=f00f9262-d93f-4f8d-a271-0dc828d2e632"
const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG_01.png?alt=media&token=3285a9ae-71c5-4584-a9c1-9b13c4a8b124"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG-02.png?alt=media&token=a9f6d065-4c56-4728-a5a5-3dc7a9dfc251"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG-03.png?alt=media&token=42fa578e-2d2e-407d-bfd2-779503e7c665"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG4.png?alt=media&token=8e9b83ba-6577-4601-8892-34d2abbd3fe3"
const img5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG5.png?alt=media&token=65698216-089d-492b-abd5-9e9564f3e46f"
const img6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG6.png?alt=media&token=3f1a3334-f02a-4588-97a0-9cc3880fb786"
const img7 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG7.png?alt=media&token=7125a59f-5ef0-4af5-9752-93889cc529aa"
const img8 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG8.png?alt=media&token=9a3d8aa8-8dbd-4778-aee2-459475e937d6"
const img9 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG9.png?alt=media&token=33656185-8131-4287-993d-7a92847ba0c3"
const img10 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG10.png?alt=media&token=e3b69381-c716-4c22-aca0-309020797644"
const img11 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG11.png?alt=media&token=78209b45-6af3-430d-b261-8cdead22383a"
const img12 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG12.png?alt=media&token=4a16872f-1fd9-4a7e-8941-38845759c566"
const img13 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG13.png?alt=media&token=d7215538-c2c6-457a-a9f8-1c111e2e00a4"
const img14 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG14.png?alt=media&token=60ecc7c7-d39d-48e5-a9a9-3c7acba44a3b"
const img15 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG15.png?alt=media&token=41f6e5aa-2f68-4bb1-8b0e-e3201a151bb8"
const img16 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG16.png?alt=media&token=3f27fec2-4337-4b62-974b-82e554608056"
const img17 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG17.png?alt=media&token=f0a5a031-343a-43d7-a9ac-9b89f9212a7d"
const img18 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG18.png?alt=media&token=3fe4ae56-5051-4fd6-85e4-5890b66e6eab"
const img19 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG19.png?alt=media&token=b25d9cb4-2ebd-4271-be04-42cd7ec33b48"
const img20 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG20.png?alt=media&token=c9ae0459-97e7-4054-ab93-a2def6418bf5"
const img21 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG21.png?alt=media&token=eceefee5-2038-41eb-ba34-8a89e3c56a67"
const img22 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG22.png?alt=media&token=24eb71dd-d2a6-417f-9fa1-cfdfa13e6b76"
const img23 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/IMG23.png?alt=media&token=4cfc54f7-b530-4b3f-8ba6-fbc925ef2de1"

export default function Keep() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/events/past">
                    <p className={styles.arrow}>Past</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.sectionDetail}>
                <h2 className={styles.title}>Keep on Dancing </h2>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img} alt="keep dancing event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>Keep on Dancing </h3>
                            <p>Sunday, March 27th</p>
                            <p>Miami</p>
                            <p>&bull; Black Loops</p>
                            <p>&bull; DJ Nigiri</p>
                            <p>&bull; Francesca Lombardo</p>
                            <p>&bull; Kenny Glasgow</p>
                            <p>&bull; Knario</p>
                            <p>&bull; O.BEE</p>
                            <p>&bull; Orazio Rispo</p>
                            <p>&bull; Richy Ahmed</p>
                            <p>&bull; Roujeeee Tuns</p>
                            <p>&bull; Shaun Reeves</p>
                            <p>&bull; Tian</p>
                            <p>&bull; Tomas Station</p>
                            <p>&bull; Vithz</p>
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}>
                    <LazyLoadImage effect="blur" src={img1} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img2} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img3} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img4} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img5} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img6} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img7} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img8} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img9} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img10} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img11} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img12} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img13} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img14} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img15} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img16} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img17} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img18} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img19} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img20} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img21} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img22} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img23} alt="tunes representation" />
                </div>
            </section >
        </section>


    )
}