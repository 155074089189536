import * as React from 'react'
import styles from "./Welcome.module.css"
import Who from '../who/WhoSection'
import Nav from './Nav'
import Agency from '../agency/Agency'
import Record from '../record/Record'
import Events from '../events/Events'
import { useEffect } from 'react';

export default function Main() {
    const whoRef = React.useRef(null)
    const agencyRef = React.useRef(null)
    const recordRef = React.useRef(null)
    const eventsRef = React.useRef(null)
    useEffect(() => {
        const url = new URL(window.location)
        const section = url ? url.search.split('=')[1] : null
        switch (section) {
            case 'who':
                whoRef.current.scrollIntoView({ behavior: "smooth" })
                break;
            case 'agency':
                agencyRef.current.scrollIntoView({ behavior: "smooth" })
                break;
            case 'record':
                recordRef.current.scrollIntoView({ behavior: "smooth" })
                break;
            case 'events':
                eventsRef.current.scrollIntoView({ behavior: "smooth" })
                break;
        }
    }, [])
    return (
        <section>
            <title>Odd Pleasures</title>
            <Nav />
            <Who containerRef={whoRef} />
            <div className={styles.divider}></div>
            <Agency containerRef={agencyRef} />
            <div className={styles.divider}></div>
            <Record containerRef={recordRef} />
            <div className={styles.divider}></div>
            <Events containerRef={eventsRef} />
        </section >

    )
}