import { Switch, Route } from 'react-router-dom'
import Welcome from './pages/welcome/Welcome'
import Consulting from './pages/who/Consulting'
import Mapping from './pages/who/Mappings'
import Project from './pages/who/Project'
import Who from './pages/who/WhoSection'
import Event from './pages/who/Event'
import AgencyDetail from './pages/agency/AgencyDetail'
import RecordDetail from './pages/record/RecordDetail'
import Main from './pages/welcome/Main'
import Upcoming from './pages/events/Upcoming'
import Past from './pages/events/Past'
import Curation from './pages/events/Curation'
import Showcase from './pages/events/Showcase'
import Shuttle from './pages/events/Shuttle'
import Tunes from './pages/events/Tunes'
import WhoDetail from "./pages/who/WhoDetail"
import Keep from './pages/events/Keep'
import Lines from './pages/events/Lines'


export default () => <Switch>
    <Route exact path="/" component={Welcome} />
    <Route path="/home" component={Main} />
    <Route exact path="/who-we-are" component={Who} />
    <Route exact path="/who-we-are/detail" component={WhoDetail} />
    <Route path="/who-we-are/services/odd-mapping" component={Mapping} />
    <Route path="/who-we-are/services/project-management" component={Project} />
    <Route path="/who-we-are/services/creative-consulting" component={Consulting} />
    <Route path="/who-we-are/services/event-curation" component={Event} />
    <Route exact path="/agency" component={AgencyDetail} />
    <Route path="/record-label" component={RecordDetail} />
    <Route exact path="/events/upcoming" component={Upcoming} />
    <Route exact path="/events/past" component={Past} />
    <Route path="/events/past/showcase" component={Showcase} />
    <Route path="/events/past/shuttle" component={Shuttle} />
    <Route path="/events/past/tunes" component={Tunes} />
    <Route path="/events/event-curation" component={Curation} />
    <Route path="/events/keep-on-dancing" component={Keep} />
    <Route path="/events/between-lines-soiree" component={Lines} />
</Switch>