import * as React from 'react'
import styles from "./Record.module.css"
import classNames from 'classnames'
import { Link } from "react-router-dom"

export default function Record({ containerRef }) {


    return (
        <section ref={containerRef} className={styles.recordSection}>
            <div className={classNames(styles.borderedDiv)}>
                <Link to="/record-label?section=digital">
                    <span className={styles.left}>
                        Digital
                        <hr className={styles.line} />
                    </span>
                </Link>
                <Link to="/record-label?section=vinyl">
                    <span className={classNames(styles.right, {
                        [styles.middleRight]: false
                    })} >
                        Vinyl
                        <hr className={styles.line} />
                    </span>
                </Link>

                <span className={styles.centered}>
                    Record label
                </span>
                <Link to="/record-label?section=oddcast">
                    <span className={classNames(styles.bottom, {
                        [styles.bottomLeft]: false
                    })}>
                        Oddcast
                        <hr className={styles.line} />
                    </span>
                </Link>
            </div>
        </section >

    )
}
