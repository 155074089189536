import * as React from 'react'
import styles from "./Who.module.css"
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"
const arrow = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/arrow.png?alt=media&token=f9cc3258-cad7-472a-bcce-d14183a91d60"
const jaime = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/jaime.png?alt=media&token=4dc3978f-790f-459e-96a8-45f030a6abbd"
const veronica = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/veronica.png?alt=media&token=ff67f955-3925-45b7-8ea9-bac1c381c71d"

export default function WhoDetail() {
    const teamRef = React.useRef(null)
    const servicesRef = React.useRef(null)
    useEffect(() => {
        const url = new URL(window.location)
        const section = url ? url.search.split('=')[1] : null
        if (section === 'team') {
            teamRef.current.scrollIntoView({ behavior: "smooth" })
        } else if (section === 'services') {
            servicesRef.current.scrollIntoView({ behavior: "smooth" })
        }

    }, [])
    return (
        <section>
            <meta
                name="keywords"
                content="odd pleasures, music, dj, production, soundcloud, creative direction, music production"
            />
            <title>Who we are</title>
            <div className={styles.navBar}>
                <Link to="/home?section=who">
                    <p className={styles.arrow}>WHO WE ARE</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.missionSection}>
                <div>
                    <h2 className={styles.title}>MISSION</h2>
                    <p className={styles.subtitle}>Odd Pleasures is a Miami-based record label, talent management agency & entertainment curator born in 2020 with the goal of creating a platform where both upcoming and well-established artists can showcase and elevate their talent.
                    </p>
                    <div className={styles.scroll}>
                        <p>scroll to explore</p>
                        <LazyLoadImage effect="blur" src={arrow} alt="arrow" />
                    </div>
                </div>
            </section>
            <section ref={teamRef} className={styles.teamSection}>
                <div  >
                    <h2 className={styles.title}>TEAM</h2>
                    <div className={styles.teamBox}>
                        <div className={styles.founderCard}>
                            <div>
                                <LazyLoadImage effect="blur" className={styles.jaime} src={jaime} alt="jaime" />
                                <h3>Jaime Bolin</h3>
                            </div>
                            <div>
                                <p>
                                    Jaime Bolin  is a music enthusiast and creative consultant majored in Business Communications. Gaining experience in renewable energy and his first gigs and events in Madrid, he moved to Miami where he started booking and managing a roster of upcoming talents while developing Odd Pleasure's record label. At the moment he's in charge of the cultural development of Enrique Tomas, blending the best music and live acts with the finest Spanish cuisine.
                                </p>
                            </div>
                        </div>
                        <div className={styles.founderCard}>
                            <div>
                                <LazyLoadImage effect="blur" className={styles.veronica} src={veronica} alt="services veronica" />
                                <h3>Veronica Lam</h3>
                            </div>
                            <div>
                                <p>Veronica Lam is a strategic communicator, in both visual and written mediums. Coming from a strong Public Relations and Economics background. Lam uses her communication knowledge in a creative way to build meaningful and effective business, campaigns and branding. She has experience in styling, video director and branding design. As a creative director, under the artist name of VeroxLam, she uses her wide range of skills to develop unique concepts with styles that are carefully curated and driven by purpose. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={servicesRef}
                className={styles.serSection}
            >
                <div>
                    <h2
                        style={{
                            color: 'white',
                        }}
                        className={styles.title}
                    >SERVICES</h2>
                    <div
                        className={styles.servicesBox}
                    >
                        <Link to="/who-we-are/services/odd-mapping">
                            <p>Odd mappings</p>
                        </Link>
                        <Link to="/who-we-are/services/project-management">
                            <p>Project Management</p>
                        </Link>
                        <Link to="/who-we-are/services/creative-consulting">
                            <p>Creative Consulting</p>
                        </Link>
                        <Link to="/who-we-are/services/event-curation">
                            <p>Event curation</p>
                        </Link>
                    </div>
                </div>
            </section>
        </section>

    )
}