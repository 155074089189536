import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Events.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"
const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra13.png?alt=media&token=7d5a821d-c818-4623-8caa-5fce4d229401"
const img = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/opfinal4.png?alt=media&token=9ec057c6-c00c-47ac-9144-aab898660e8d"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra14.png?alt=media&token=67e0e68a-ff71-4b82-85bf-3b56685877bc"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra15.png?alt=media&token=d55d6bbd-423c-4297-bc78-4bae35a50a09"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra16.png?alt=media&token=dfc9d484-18ea-4390-b7a8-90a1acd5426c"
const img5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra17.png?alt=media&token=7da34f07-32de-4f73-bca5-0f17b8205d9a"
const img6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra18.png?alt=media&token=8388b327-6546-4bda-b97e-34e130dd62ba"
const img7 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra19.png?alt=media&token=b6e78db8-59ae-449d-b18c-3d36164c5eb1"
const img8 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra20.png?alt=media&token=62719a08-38f9-49c7-ab29-49b5a9b8558c"
const img9 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra21.png?alt=media&token=60b1c8dd-9feb-4049-aac4-c70129d075aa"
const img10 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra22.png?alt=media&token=cd00e5a7-026f-45d8-a816-6edab8524a09"

export default function Tunes() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/events/past">
                    <p className={styles.arrow}>Past</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.sectionDetail}>
                <h2 className={styles.title}>ODD TUNES</h2>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img} alt="tunes event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>odd tunes @bogart house</h3>
                            <p>September 14th, 2021</p>
                            <p>New York City</p>
                            <p>&bull; Roujeee Tunes</p>
                            <p>&bull; Mr. Lin</p>
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}>
                    <LazyLoadImage effect="blur" src={img1} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img2} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img3} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img4} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img5} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img6} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img7} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img8} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img9} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img10} alt="tunes representation" />
                </div>
            </section >
        </section>


    )
}