import * as React from 'react'
import styles from "./Events.module.css"
import classNames from 'classnames'
import { Link } from "react-router-dom"

export default function Events({ containerRef }) {

    return (
        <section ref={containerRef} className={styles.whoSection}>
            <meta
                name="keywords"
                content="odd pleasures, music, dj, production, soundcloud, creative direction, music production"
            />
            <div className={classNames(styles.borderedDiv)}>
                <Link to="/events/upcoming">
                    <span className={styles.left}>
                        Upcoming
                        <hr className={styles.line} />
                    </span>
                </Link>
                <Link to="/events/past">
                    <span className={classNames(styles.right, {
                        [styles.middleRight]: false
                    })} >
                        Past
                        <hr className={styles.line} />
                    </span>
                </Link>

                <span className={styles.centered}>
                    Events
                </span>
                <Link to="/events/event-curation">
                    <span className={classNames(styles.bottom, {
                        [styles.bottomLeft]: false
                    })}>
                        Event curation
                        <hr className={styles.line} />
                    </span>
                </Link>
            </div>

        </section >

    )
}