import * as React from 'react'
import styles from "./Welcome.module.css"
import { Link } from "react-router-dom"
import Lottie from 'react-lottie'
import * as animationData from '../../assets/animations/odd.json'
import * as animationDataMobile from '../../assets/animations/odd-mobile.json'

const who = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/ODD2.png?alt=media&token=4771a756-3b9d-482e-9184-53bda385c54b"
const agency = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/newimage.png?alt=media&token=2760fafb-22a2-472f-af25-4c462d4e2d1a"
const record = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/ODD3.png?alt=media&token=d4a6cf16-7a84-4db2-a190-4e9a3461d5f5"
const events = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/ODD4.png?alt=media&token=27a49a23-22c1-40eb-a72b-d9d9ab52cfc8"
const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP_Circle_logo_white.png?alt=media&token=e235b092-8327-46a3-b0f3-159947135496"
const logo2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/Odd%20Pleasure%20white%20logo%201.png?alt=media&token=799ab8f6-63ba-44eb-aff4-8ce40c919c21"

export default function Welcome() {

    React.useEffect(() => {
        localStorage.setItem('noAnimation', Date.now())
    }, [])

    const [done, setDone] = React.useState(false)

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: window.innerWidth < 760 ? animationDataMobile : animationData,
        // animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const eventListeners = [
        {
            eventName: 'complete',
            callback: () => setDone(true)
        },
    ]

    let animate = !localStorage.getItem('noAnimation')

    if (!animate) {
        const timeLapsed = Date.now() - localStorage.getItem('noAnimation')
        const MINS = 1000 * 60 * 10// 10m
        if (timeLapsed > MINS) {
            animate = true
        }
    }



    if (animate && !done) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }} >
                <Lottie eventListeners={eventListeners} options={defaultOptions} />
            </div>
        )
    }
    return <Main />
}

const Main = () => (
    <section>
        <meta
            name="keywords"
            content="odd pleasures, music, dj, production, soundcloud, creative direction, music production"
        />
        <title>Odd Pleasures</title>
        <div className={styles.logoBanner}>
            <img className={styles.logoSmall} src={logo} alt="logo odd pleasures" />
        </div>
        <section className={styles.welcome} >
            <div className={styles.sectionBox}>
                <div className={styles.section} style={{ backgroundImage: `url("${who}")` }}>
                    <Link to="/home">
                        <div>
                            <h2>WHO WE ARE</h2>
                            <p>Mission</p>
                            <p>Team</p>
                            <p>Services</p>
                        </div>
                    </Link>
                </div>
                <div className={styles.section} style={{ backgroundImage: `url("${agency}")` }}>
                    <Link to="/home?section=agency">
                        <div>
                            <h2>Agency</h2>
                            <p>Management</p>
                            <p>Creactive Direction</p>
                        </div>
                    </Link>
                </div>
                <div className={styles.section} style={{ backgroundImage: `url("${record}")` }}>
                    <Link to="/home?section=record">
                        <div>
                            <h2>RECORD LABEL</h2>
                            <p>Digital</p>
                            <p>Vinyl</p>
                            <p>Oddcast</p>
                        </div>
                    </Link>
                </div>
                <div className={styles.section} style={{ backgroundImage: `url("${events}")` }}>
                    <Link to="/home?section=events">
                        <div>
                            <h2>EVENTS</h2>
                            <p>Upcoming</p>
                            <p>Past</p>
                            <p>Curation</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className={styles.banner}>
                <marquee>
                    <a href="mailto:info@oddpleasures.com" aria-label="Mail" rel="noopener noreferrer">

                        EMAIL US FOR A GOOD TIME . info@oddpleasures.com . EMAIL US FOR A GOOD TIME . info@oddpleasures.com . EMAIL US FOR A GOOD TIME . info@oddpleasures.com . EMAIL US FOR A GOOD TIME . info@oddpleasures.com . EMAIL US FOR A GOOD TIME . info@oddpleasures.com . EMAIL US FOR A GOOD TIME . info@oddpleasures.com . EMAIL US FOR A GOOD TIME . info@oddpleasures.com . EMAIL US FOR A GOOD TIME . info@oddpleasures.com . EMAIL US FOR A GOOD TIME . info@oddpleasures.com .
                    </a>
                </marquee>
            </div>
            <img className={styles.logo} src={logo2} alt="logo odd pleasures" />
        </section >
    </section >
)