import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Events.module.css"
import { Link } from "react-router-dom"
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"

export default function Upcoming() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <meta
                name="keywords"
                content="odd pleasures, music, dj, production, soundcloud, creative direction, music production"
            />
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/home?section=events">
                    <p className={styles.arrow}>Events</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.sectionDetail}>
                <h2 className={styles.title}>Upcoming</h2>
                <h3 className={styles.txt}>Stay tuned for upcoming events</h3>
            </section >
        </section>


    )
}