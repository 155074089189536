import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Who.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/mapp-01.png?alt=media&token=0a43dc3c-9100-4e62-ad45-50352c35eae3"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/mapp-02.png?alt=media&token=79dea8b2-6db7-429b-9d2d-882f7bd712cf"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/mapp-03.png?alt=media&token=05f93854-38b3-4cae-a146-e85277d73c6b"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/mapp-04.png?alt=media&token=397302c7-0da3-451d-9056-3cd133ae6001"
const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP_Circle_logo_white.png?alt=media&token=e235b092-8327-46a3-b0f3-159947135496"

export default function Mapping() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/who-we-are/detail?section=services">
                    <p className={styles.arrow}>Services</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.serviceDetail}>
                <h2 className={styles.title}>ODD MAPPINGS</h2>
                <div className={styles.serviceHeader}>
                    <div>
                        <p>Odd Mappings is a multidisciplinary platform for visual creatives to gather and create custom-made experiences depending on the needs of each client. </p>
                    </div>
                    <a href="mailto:info@oddpleasures.com" aria-label="Mail" rel="noopener noreferrer">
                        <button className={styles.btn}>Book now</button>
                    </a>
                </div>
                <div className={styles.serviceContent}>
                    <LazyLoadImage effect="blur" loading="lazy" src={img1} alt="services representation" />
                    <LazyLoadImage effect="blur" loading="lazy" src={img2} alt="services representation" />
                    <LazyLoadImage effect="blur" loading="lazy" src={img3} alt="services representation" />
                    <LazyLoadImage effect="blur" loading="lazy" src={img4} alt="services representation" />
                </div>
            </section >
        </section >


    )
}