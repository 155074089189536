import * as React from 'react'
import styles from "./Agency.module.css"
import { useEffect } from 'react';
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP_Circle_logo_white.png?alt=media&token=e235b092-8327-46a3-b0f3-159947135496"
const arrow = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/arrow.png?alt=media&token=f9cc3258-cad7-472a-bcce-d14183a91d60"

const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/id1.png?alt=media&token=79716890-c451-4d30-871c-230a3d535cb6"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/id2.png?alt=media&token=db40b150-3e4d-40f3-a483-f9271585c072"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/id3.png?alt=media&token=94207d31-f2b1-48a5-9d17-b02618818b31"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/id4.png?alt=media&token=39a0d78f-2809-419e-8b2d-9647051ffc90"
const img5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/id5.png?alt=media&token=6246814a-6a35-444d-9f25-6b5c546ad894"
const img6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/id6.png?alt=media&token=f65cb911-a600-46af-bb57-d84396d8d931"

const people1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/creative%2Fpeople1.png?alt=media&token=a2d5bf16-fea1-486b-80ae-24840b85b501"
const people2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/creative%2Fpeople2.png?alt=media&token=a7073e97-96bb-487c-9b71-7b7668f390d2"
const people3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/creative%2Fpeople3.png?alt=media&token=e784cbb0-fed1-4418-973f-9de0f21106b4"
const people4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/creative%2Fpeople4.png?alt=media&token=052ed48d-8173-4b34-bc04-550546b92520"
const people5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/creative%2Fpeople5.png?alt=media&token=dfb41334-64c8-4506-bacb-e4f4fb7069b6"
const people6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/creative%2Fpeople6.png?alt=media&token=e45db370-fc3a-4ffa-ba18-327234bbb231"

export default function AgencyDetail() {
    const creativeRef = React.useRef(null)
    const manageRef = React.useRef(null)
    useEffect(() => {
        const url = new URL(window.location)
        const section = url ? url.search.split('=')[1] : null
        if (section === 'creative') {
            creativeRef.current.scrollIntoView({ behavior: "smooth" })
        } else if (section === 'manage') {
            manageRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }, [])

    return (
        <section>
            <meta
                name="keywords"
                content="odd pleasures, music, dj, production, soundcloud, creative direction, music production"
            />
            <title>Agency</title>
            <div className={styles.navBar}>
                <Link to="/home?section=agency">
                    <p className={styles.arrow}>agency</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" loading="lazy" />
                </Link>
            </div >
            <section ref={manageRef} className={styles.managementSection}>
                <div>
                    <h2 className={styles.title}>Management</h2>
                    <div className={styles.managers}>
                        <LazyLoadImage effect="blur" src={img1} alt="management representation" />
                        <LazyLoadImage effect="blur" src={img2} alt="management representation" />
                        <LazyLoadImage effect="blur" src={img3} alt="management representation" />
                        <LazyLoadImage effect="blur" src={img4} alt="management representation" />
                        <LazyLoadImage effect="blur" src={img5} alt="management representation" />
                        <LazyLoadImage effect="blur" src={img6} alt="management representation" />
                    </div>
                    <div className={styles.scroll}>
                        <p>scroll to explore</p>
                        <img src={arrow} alt="arrow" />
                    </div>
                </div>
            </section >
            <section ref={creativeRef} className={styles.directorsSection}>
                <div>
                    <h2 className={styles.title}>creative direction <br /> by True Ground
                    </h2>
                    <p className={styles.subtitle}>Behind every great human achievement, there is a team. From medicine and space travel, to disaster response and pizza deliveries. Our products help teams all over the planet advance humanity through the power of music. Our mission is to help unleash the potential of every team.
                    </p>
                    <br />
                    <div className={styles.creativeBox}>
                        <LazyLoadImage effect="blur" src={people1} alt="person dancing" />
                        <LazyLoadImage effect="blur" src={people2} alt="woman thinking" />
                        <LazyLoadImage effect="blur" src={people3} alt="man call someone" />
                        <LazyLoadImage effect="blur" src={people4} alt="man thinking" />
                        <LazyLoadImage effect="blur" src={people5} alt="woman with a big bear" />
                        <LazyLoadImage effect="blur" src={people6} alt="woman surrounded by flowers" />
                    </div>
                    <br />
                    <a href="https://www.instagram.com/true___ground/" target="_blank" rel="noopener noreferrer">
                        <button className={styles.btn}>See more</button>
                    </a>
                </div>
            </section >
        </section>


    )
}