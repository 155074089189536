import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Events.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"
const img = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/poster-02.png?alt=media&token=62301eaf-2b48-411d-adf0-c18149854468"
const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV01.png?alt=media&token=5afd0c00-9dac-4b8b-815c-8d43d11cc01b"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV02.png?alt=media&token=f4bf0c7c-dd45-49ff-8f44-f51148d71c1b"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV03.png?alt=media&token=f52721c0-f75c-4de6-b042-ed1a6460c138"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV04.png?alt=media&token=619896c7-4a61-4362-aca7-d8771fc58a12"
const img5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV05.png?alt=media&token=1c0ee64d-d067-4459-ba81-3567efeab0b6"
const img6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV06.png?alt=media&token=c452478c-e235-436b-a9c3-c9ac6600c11c"
const img7 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV07.png?alt=media&token=8e775512-66b4-4abc-9aa7-431e916f2882"
const img8 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV08.png?alt=media&token=2b993fff-3136-4d06-ace4-8d122a6a4e9f"
const img9 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV09.png?alt=media&token=5d164f88-af4c-4015-9647-9c3c6f8e79a8"
const img10 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV10.png?alt=media&token=592dd346-0408-4a7c-9a3d-fe229cf14d53"
const img11 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV11.png?alt=media&token=1a3bdce7-2cd6-4129-ac91-e93396d2e211"
const img12 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV12.png?alt=media&token=c2983fb0-673e-4af3-8a13-02c30367386d"
const img13 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/EV13.png?alt=media&token=381ec551-ef0c-4bdd-be3e-a4466db43b56"

export default function Lines() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/events/past">
                    <p className={styles.arrow}>Past</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.sectionDetail}>
                <h2 className={styles.title}>Between Lines Soirée</h2>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img} alt="between lines event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>Between Lines Soirée</h3>
                            <p> Saturday, April 16th</p>
                            <p>Miami</p>
                            <p>&bull; Calabasa</p>
                            <p>&bull; Frny</p>
                            <p>&bull; Tian</p>
                            <p>&bull; Mr. Lin</p>
                            <p>&bull; Vitess</p>
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}>
                    <LazyLoadImage effect="blur" src={img1} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img2} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img3} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img4} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img5} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img6} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img7} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img8} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img9} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img10} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img11} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img12} alt="tunes representation" />
                    <LazyLoadImage effect="blur" src={img13} alt="tunes representation" />
                </div>
            </section >
        </section>


    )
}