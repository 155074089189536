import * as React from 'react'
import styles from "./Welcome.module.css"
import { Link } from "react-router-dom"

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP_Circle_logo_white.png?alt=media&token=e235b092-8327-46a3-b0f3-159947135496"

export default function Nav() {

    return (
        <section className={styles.nav}>
            <Link to="/">
                <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
            </Link>
        </section >

    )
}