import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Events.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"
const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra6.png?alt=media&token=8ada539a-0850-422c-a782-e9409106072f"
const img = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/opfinal3.png?alt=media&token=0d69d485-5ef3-4147-8443-63513d1eb069"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra7.png?alt=media&token=587200d6-b2e3-4c4e-8a7b-4d00f297a739"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra8.png?alt=media&token=bd201bd5-5b5d-47bf-83ec-912540c0e380"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra9.png?alt=media&token=35c06bc7-b5e8-4ef1-afab-d1f7a999fa55"
const img5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra10.png?alt=media&token=0b69a8c7-f2ba-449f-928e-6e5b13a5e876"
const img6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra11.png?alt=media&token=d3919b9d-5135-46c1-b20d-43fb39f8fe58"
const img7 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/extra12.png?alt=media&token=d4b29c04-22f3-4853-87a2-f605df9013da"

export default function Shuttle() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/events/past">
                    <p className={styles.arrow}>Past</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.sectionDetail}>
                <h2 className={styles.title}>The shuttle</h2>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img} alt="shuttle event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>THE shuttle @ atv records</h3>
                            <p>August 26th, 2021</p>
                            <p>Miami</p>
                            <p>&bull; Avstin Frank</p>
                            <p>&bull; Bodeler</p>
                            <p>&bull; Ennio Skoto</p>
                            <p>&bull; Mr. Lin</p>
                            <p>&bull; Tomi Jonas</p>
                            <p>&bull; Tian</p>
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}>
                    <LazyLoadImage effect="blur" src={img1} alt="shuttle representation" />
                    <LazyLoadImage effect="blur" src={img2} alt="shuttle representation" />
                    <LazyLoadImage effect="blur" src={img3} alt="shuttle representation" />
                    <LazyLoadImage effect="blur" src={img4} alt="shuttle representation" />
                    <LazyLoadImage effect="blur" src={img5} alt="shuttle representation" />
                    <LazyLoadImage effect="blur" src={img6} alt="shuttle representation" />
                    <LazyLoadImage effect="blur" src={img7} alt="shuttle representation" />
                </div>
            </section >
        </section>


    )
}