import * as React from 'react'
import styles from "./Agency.module.css"
import classNames from 'classnames'
import { Link } from "react-router-dom"

export default function Agency({ containerRef }) {

    return (
        <section ref={containerRef} className={styles.agencySection}>
            <div className={classNames(styles.borderedDiv)}>
                <Link to="/agency?section=manage">
                    <span className={classNames(styles.right, {
                        [styles.middleRight]: true
                    })} >
                        Management
                        <hr className={styles.line} />
                    </span>
                </Link>
                <span className={styles.centered}>
                    Agency
                </span>
                <Link to="/agency?section=creative">
                    <span className={classNames(styles.bottom, {
                        [styles.bottomLeft]: true
                    })}>
                        Creative direction
                        <hr className={styles.line} />
                    </span>
                </Link>
            </div>

        </section >

    )
}

