import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Who.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP%20Circle%20logo%20white.png?alt=media&token=79fbe546-0d40-4cae-9131-85c70aa45711"
const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/manage1.png?alt=media&token=e59e9ae8-faad-4aaf-b94b-7d804d315ca7"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/pm-01.png?alt=media&token=0a37783c-594e-4c8a-bb68-79be05f9a394"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/manage2.png?alt=media&token=9f5dcbcb-dda4-4cdb-a07c-0141a9eec738"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/pm-02.png?alt=media&token=bf03bf37-e748-4ad2-839e-9d616b7fdca6"

export default function Project() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/who-we-are/detail?section=services">
                    <p className={styles.arrow}>Services</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.serviceDetail}>
                <h2 className={styles.title}>Project management</h2>
                <div className={styles.serviceHeader}>
                    <div>
                        <p>Our team will provide a full development for any type of artistic project. From creative studios to boutique experiences, we'll help you find the best resources to reach your goals.
                        </p>
                    </div>
                    <a href="mailto:info@oddpleasures.com" aria-label="Mail" rel="noopener noreferrer">
                        <button className={styles.btn}>Book now</button>
                    </a>
                </div>
                <div className={styles.serviceContent}>
                    <LazyLoadImage effect="blur" src={img1} alt="project representation" />
                    <LazyLoadImage effect="blur" src={img2} alt="project representation" />
                    <LazyLoadImage effect="blur" src={img3} alt="project representation" />
                    <LazyLoadImage effect="blur" src={img4} alt="project representation" />
                </div>
            </section >
        </section>


    )
}