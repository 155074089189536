import * as React from 'react'
import { useEffect } from 'react';
import styles from "./Events.module.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP_Circle_logo_white.png?alt=media&token=e235b092-8327-46a3-b0f3-159947135496"
const img = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/opfinal2%201.png?alt=media&token=6214ff75-35f4-4733-aaca-c73bdde885ce"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/opfinal3.png?alt=media&token=0d69d485-5ef3-4147-8443-63513d1eb069"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/opfinal4.png?alt=media&token=9ec057c6-c00c-47ac-9144-aab898660e8d"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/opfinal2%202.png?alt=media&token=dfdb74bd-cb5a-4ddf-bd54-db0251c554c1"
const img5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/poster-01.png?alt=media&token=f00f9262-d93f-4f8d-a271-0dc828d2e632"
const img6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/poster-02.png?alt=media&token=62301eaf-2b48-411d-adf0-c18149854468"

export default function Past() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])
    return (
        <section>
            <title>Events</title>
            <div className={styles.navBar}>
                <Link to="/home?section=events">
                    <p className={styles.arrow}>Events</p>
                </Link>
                <Link to="/">
                    <img loading="lazy" className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section className={styles.sectionDetail}>
                <h2 className={styles.title}>Past</h2>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img} alt="showcase event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>THE showcase @ wynwood factory</h3>
                            <p>June 4th, 2021</p>
                            <p>Miami</p>
                            <p> &bull; Adrien Calvet</p>
                            <p>&bull; Avstin Frank</p>
                            <p>&bull; Coupe Melba</p>
                            <p>&bull; E.T.H (Italy)</p>
                            <p>&bull; Miluhska</p>
                            <p>&bull; Odd Ones Out</p>
                        </div>
                        <Link to="/events/past/showcase">
                            <button className={styles.btn}>Show gallery</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img2} alt="shuttle event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>THE shuttle @ atv records</h3>
                            <p>August 26th, 2021</p>
                            <p>Miami</p>
                            <p>&bull; Avstin Frank</p>
                            <p>&bull; Bodeler</p>
                            <p>&bull; Ennio Skoto</p>
                            <p>&bull; Mr. Lin</p>
                            <p>&bull; Tomi Jonas</p>
                            <p>&bull; Tian</p>
                        </div>
                        <Link to="/events/past/shuttle">
                            <button className={styles.btn}>Show gallery</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img3} alt="tunes event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>odd tunes @ bogart house</h3>
                            <p>September 14th, 2021</p>
                            <p>New York City</p>
                            <p>&bull; Roujeee Tunes</p>
                            <p>&bull; Mr. Lin</p>
                        </div>
                        <Link to="/events/past/tunes">
                            <button className={styles.btn}>Show gallery</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img4} alt="mezcalista event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>1 YEAR ANNIVERSARY @ Sky Yard</h3>
                            <p>February 11, 2022</p>
                            <p>Miami</p>
                            <p>&bull; Adrien Calvet</p>
                            <p>&bull; DJ Nigiri</p>
                            <p>&bull; Michelle Mavris</p>
                            <p>&bull; Sofia Luna</p>
                            <p>&bull; Tian</p>
                        </div>
                        <Link to="/events/past" style={{ opacity: "0" }}>
                            <button className={styles.btn}></button>
                        </Link>
                    </div>
                </div>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img5} alt="keep dancing event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>Keep on Dancing @ Miami</h3>
                            <p>Sunday, March 27th</p>
                            <p>Miami</p>
                            <p>&bull; Black Loops</p>
                            <p>&bull; DJ Nigiri</p>
                            <p>&bull; Francesca Lombardo</p>
                            <p>&bull; Kenny Glasgow</p>
                            <p>&bull; Knario</p>
                            <p>&bull; O.BEE</p>
                            <p>&bull; Orazio Rispo</p>
                            <p>&bull; Richy Ahmed</p>
                            <p>&bull; Roujeeee Tuns</p>
                            <p>&bull; Shaun Reeves</p>
                            <p>&bull; Tian</p>
                            <p>&bull; Tomas Station</p>
                            <p>&bull; Vithz</p>
                        </div>
                        <Link to="/events/keep-on-dancing" >
                            <button className={styles.btn}>Show gallery</button>
                        </Link>
                    </div>
                </div>
                <div className={styles.box}>
                    <LazyLoadImage effect="blur" src={img6} alt="between lines event" />
                    <div className={styles.boxInfo}>
                        <div className={styles.boxText}>
                            <h3>Between Lines Soirée @ Miami</h3>
                            <p>Saturday, April 16th</p>
                            <p>Miami</p>
                            <p>&bull; Calabasa</p>
                            <p>&bull; Frny</p>
                            <p>&bull; Tian</p>
                            <p>&bull; Mr. Lin</p>
                            <p>&bull; Vitess</p>
                        </div>
                        <Link to="/events/between-lines-soiree" >
                            <button className={styles.btn}>Show gallery</button>
                        </Link>
                    </div>
                </div>
            </section >
        </section>
    )
}
