import * as React from 'react'
import styles from "./Record.module.css"
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const logo = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/OP_Circle_logo_white.png?alt=media&token=e235b092-8327-46a3-b0f3-159947135496"

const img1 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital1.png?alt=media&token=f5d5eb0c-a751-4a07-809d-7bfac407edfe"
const img2 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital2.png?alt=media&token=04ec3954-2882-4fe9-bd9e-0c6fc43b08dd"
const img3 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital3.png?alt=media&token=6777ca01-3691-40cb-b17c-9f024a6d10ad"
const img4 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital4.png?alt=media&token=116b9e8a-07da-4440-bc4a-be21ef9e1512"
const img5 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital5.png?alt=media&token=63402bbf-528a-46f7-8c11-5d6ef913ff42"
const img6 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital6.png?alt=media&token=e4e53123-cb2c-49c0-9e60-cf18cd1e53f1"
const img7 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital7.png?alt=media&token=49b27b45-8af7-44dc-beb4-07b6be90c2bd"
const img8 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital8.png?alt=media&token=3f929685-bc0e-49f8-8889-bf0f95f32ac6"
const img9 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital9.png?alt=media&token=4ab534c4-709f-4612-8540-161bea2ba9ae"
const img10 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital10.png?alt=media&token=8dfaf646-7e14-4848-bf5f-14429c8d9538"
const img11 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/digital11.png?alt=media&token=c8eb0aff-2abd-4462-aa5c-d08a04355916"

const img12 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast1.png?alt=media&token=51e32fc4-ea6e-42d2-8445-05bd11d51b32"
const img13 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast2.png?alt=media&token=90dfc634-9fee-43ce-a511-06c9a43a1121"
const img14 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast3.png?alt=media&token=2cf7f860-bfcb-4c7f-ab13-45a99f52bc5c"
const img15 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast4.png?alt=media&token=1acf172f-c7f0-457c-933c-27733809e717"
const img16 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast5.png?alt=media&token=dc7d45f3-3771-4bb4-a760-8805202786a8"
const img17 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast6.png?alt=media&token=5f862725-df29-47e3-bfd7-ae8820baa768"
const img18 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast7.png?alt=media&token=d45b9ea8-dadf-487f-aef1-adcedb393ec8"
const img19 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast8.png?alt=media&token=8b233f93-cff9-4fec-a19f-b97e434761d5"
const img20 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast9.png?alt=media&token=44e60f07-7125-4adb-b747-9209b3529e91"
const img21 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast10.png?alt=media&token=5a6b3de2-22bf-4b80-b372-df3eb6ce750e"
const img22 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/cast11.png?alt=media&token=2c921a18-5fce-476b-b17b-052444f2262c"
const img23 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/creative%2Foddcast12.png?alt=media&token=06bbd341-d9d9-4f64-86cb-b49b0f7a6379"
const img24 = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/creative%2Foddcast13.png?alt=media&token=a462d07b-e6ce-4c5d-82d4-465844e08617"

const vinyl = "https://firebasestorage.googleapis.com/v0/b/odd-pleasures.appspot.com/o/vinyl.png?alt=media&token=e76436da-4ed0-4b5f-906c-621d5bab00fd"


export default function RecordDetail() {
    const digitalRef = React.useRef(null)
    const vinylRef = React.useRef(null)
    const oddcastRef = React.useRef(null)
    useEffect(() => {
        const url = new URL(window.location)
        const section = url ? url.search.split('=')[1] : null
        switch (section) {
            case 'digital':
                digitalRef.current.scrollIntoView({ behavior: "smooth" })
                break;
            case 'vinyl':
                vinylRef.current.scrollIntoView({ behavior: "smooth" })
                break;
            case 'oddcast':
                oddcastRef.current.scrollIntoView({ behavior: "smooth" })
                break;
        }
    }, [])
    return (
        <section>
            <meta
                name="keywords"
                content="odd pleasures, music, dj, production, soundcloud, creative direction, music production"
            />
            <title>Record</title>
            <div className={styles.navBar}>
                <Link to="/home?section=record">
                    <p className={styles.arrow}>record label</p>
                </Link>
                <Link to="/">
                    <img className={styles.logoNav} src={logo} alt="logo odd pleasures" />
                </Link>
            </div >
            <section ref={digitalRef} className={styles.digitalSection} >
                <h2 className={styles.title}>Digital</h2>
                <div className={styles.imgsBox}>
                    <div className={styles.image} style={{ backgroundImage: `url("${img1}")` }}>
                        <div className={styles.cover}>
                            <a href='https://open.spotify.com/album/481kvPBt7BfJ1vmZ5tsHyZ?si=mJvCEhTDR6GgBSEnlOvUFA' target="_blank" rel="noopener noreferrer">
                                <button className={styles.btn}>Listen</button>
                            </a>
                            <a href="https://oddpleasures.bandcamp.com/album/goras-road-to-space" target="_blank" rel="noopener noreferrer">
                                <button className={styles.btn}>Buy</button>
                            </a>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img2}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/3JsPG4kX4tiIOOwOmACQWn?si=SbG4WDn2RL-rnZa6CLPb6Q' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/adrien-calvet-coupe-melba-pelota" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img3}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/08Wzs0kGsMlviPYNizJXE1?si=C6TGeYAJRj6bu5oxg4557A' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/odd-ones-out-lou-flores-141-flushing-ave" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img4}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/7DXzcT1vD7q9bQWGRyZc82?si=zLtRcKAtTsmjDkutB9uIIA' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/avstin-frank-imperial-goods" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img5}")` }}>
                        <div className={styles.cover}>
                            <a href='https://open.spotify.com/album/1lqzNo4504oFlt3Dnc34oR?si=95Suwh3hRMqXZhUY4hodnw' target="_blank" rel="noopener noreferrer">
                                <button className={styles.btn}>Listen</button>
                            </a>
                            <a href="https://oddpleasures.bandcamp.com/album/calabasa-whos-the-blacksheep" target="_blank" rel="noopener noreferrer">
                                <button className={styles.btn}>Buy</button>
                            </a>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img6}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/2vSkR8akJ78hokMqffQx1Q?si=BSCA8w52TquH8Fld6IiUGg' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/fauch-ondulations" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img7}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/43LIGrtQ9wy4roomXNr5DH?si=ZLfrnP6hTW6L32ouTsaJew' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/baban-may-i-have-your-order-please" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img8}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/3PP1bP9O5w1ZahJQ2scrk4?si=fc63dSc1Sju8v2aTr_gl4w' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/riccardo-ricci-flight-401-feat-miluhska" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img9}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/5S8R8uAsilCpOUViOPeB64?si=AkqZioNNQV-59TErY4W0PQ' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/adrien-calvet-inversed-tapes" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.image} style={{ backgroundImage: `url("${img10}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/4MqVqhSa8B5xuHfVc5vkGr?si=lOZ9HCPKToWW848aGYFE5w' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/jnjs-quintage" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>                            </div>
                    </div>

                    <div className={styles.image} style={{ backgroundImage: `url("${img11}")` }}>
                        <div className={styles.cover}>
                            <div className={styles.cover}>
                                <a href='https://open.spotify.com/album/63tZNw5fu9NgVHdl5s2eKL?si=bf5WrzXsTDiJSi7IdWfhaw' target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Listen</button>
                                </a>
                                <a href="https://oddpleasures.bandcamp.com/album/marios-groove-stargate" target="_blank" rel="noopener noreferrer">
                                    <button className={styles.btn}>Buy</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section ref={vinylRef}>
                <section className={styles.vinylSection}>
                    <div>
                        <h2 className={styles.title}>Vinyl</h2>
                        <LazyLoadImage effect="blur" src={vinyl} alt="vinyl" />
                        <h2 className={styles.title}>Coming Soon . . .</h2>
                    </div>

                </section >
            </section>
            <section className={styles.digitalSection} ref={oddcastRef}>
                <h2 className={styles.title}>Oddcast</h2>
                <div className={styles.imgsBox}>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast001-fauche-fr?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img17}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast002-goras-ro?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img16}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast003-avstin-frank-us?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img15}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast004-vithz-it?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img20}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast005-sofia-duz?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img21}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast006-miked?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img13}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast007-thurman?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img14}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast008-alkmst-vinyl-only?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img12}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast009-tian?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img22}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast010-funsize?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img19}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast011-dj-nigiri?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img18}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast012-mr-lin-one-year-of-odd-pleasures?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img24}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                    <a href='https://soundcloud.com/oddpleasuresmiami/oddcast-013-black-loops-live-at-sisyphos-1' target="_blank" rel="noopener noreferrer">
                        <div className={styles.image} style={{ backgroundImage: `url("${img23}")` }}>
                            <div className={styles.cover}>
                                <button className={styles.btn}>Listen</button>
                            </div>
                        </div>
                    </a>
                </div>
            </section >
        </section>


    )
}
